import React from "react";

export const Abc = () => (
  <svg
    width="42"
    height="44"
    viewBox="0 0 42 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26 20H19C15.14 20 12 16.859 12 13C12 9.141 15.14 6 19 6H26C27.104 6 28 6.896 28 8V18C28 19.104 27.104 20 26 20ZM19 10C17.346 10 16 11.346 16 13C16 14.654 17.346 16 19 16H24V10H19Z"
      fill="#E86C60"
    />
    <path
      d="M26 20C24.896 20 24 19.104 24 18V6C24 4.897 23.103 4 22 4H18C16.896 4 16 3.104 16 2C16 0.896 16.896 0 18 0H22C25.309 0 28 2.691 28 6V18C28 19.104 27.104 20 26 20Z"
      fill="#E86C60"
    />
    <path
      d="M2 44C0.896 44 0 43.104 0 42V20C0 18.896 0.896 18 2 18C3.104 18 4 18.896 4 20V42C4 43.104 3.104 44 2 44Z"
      fill="#72C472"
    />
    <path
      d="M8 44H2C0.896 44 0 43.104 0 42V30C0 28.896 0.896 28 2 28H8C12.411 28 16 31.589 16 36C16 40.411 12.411 44 8 44ZM4 40H8C10.206 40 12 38.206 12 36C12 33.794 10.206 32 8 32H4V40Z"
      fill="#72C472"
    />
    <path
      d="M36 44H32C28.691 44 26 41.309 26 38V32C26 28.691 28.691 26 32 26H36C38.134 26 40.124 27.147 41.194 28.995C41.748 29.951 41.422 31.175 40.466 31.728C39.51 32.281 38.286 31.956 37.733 31C37.376 30.384 36.711 30 36 30H32C30.897 30 30 30.897 30 32V38C30 39.103 30.897 40 32 40H36C36.712 40 37.376 39.616 37.734 38.998C38.287 38.042 39.51 37.716 40.467 38.269C41.423 38.822 41.749 40.045 41.196 41.001C40.125 42.852 38.134 44 36 44Z"
      fill="#43A6DD"
    />
  </svg>
);
