import React from "react";

export const Chalkboard = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.0002 47C37.8046 47 37.6132 46.9426 37.4499 46.8349C37.2865 46.7271 37.1583 46.5739 37.0812 46.394L31.0812 32.394C30.9812 32.1511 30.9807 31.8785 31.08 31.6352C31.1793 31.392 31.3703 31.1976 31.6118 31.094C31.8533 30.9905 32.1258 30.9862 32.3705 31.082C32.6152 31.1778 32.8123 31.366 32.9192 31.606L38.9192 45.606C39.0237 45.8498 39.0271 46.125 38.9286 46.3712C38.8302 46.6175 38.638 46.8145 38.3942 46.919C38.2699 46.973 38.1358 47.0006 38.0002 47Z"
      fill="#B3B3B3"
    />
    <path
      d="M26 1C25.7348 1 25.4804 1.10536 25.2929 1.29289C25.1054 1.48043 25 1.73478 25 2V5H27V2C27 1.73478 26.8946 1.48043 26.7071 1.29289C26.5196 1.10536 26.2652 1 26 1Z"
      fill="#B3B3B3"
    />
    <path
      d="M26.9997 11C28.0605 11 29.078 11.4214 29.8281 12.1716C30.5782 12.9217 30.9997 13.9391 30.9997 15C30.9997 16.0609 30.5782 17.0783 29.8281 17.8284C29.078 18.5786 28.0605 19 26.9997 19H15.9997V33H44.9997V6H14.9997C14.9999 6.93221 14.8132 7.85501 14.4506 8.71381C14.088 9.57262 13.5569 10.35 12.8887 11H26.9997Z"
      fill="#444444"
    />
    <path
      d="M8 11C10.7614 11 13 8.76142 13 6C13 3.23858 10.7614 1 8 1C5.23858 1 3 3.23858 3 6C3 8.76142 5.23858 11 8 11Z"
      fill="#5A7A84"
    />
    <path
      d="M29 15C29 14.4696 28.7893 13.9609 28.4142 13.5858C28.0391 13.2107 27.5304 13 27 13H7C5.67441 13.0016 4.40356 13.5289 3.46622 14.4662C2.52888 15.4036 2.00159 16.6744 2 18V44.5C2 45.163 2.26339 45.7989 2.73223 46.2678C3.20107 46.7366 3.83696 47 4.5 47C5.16304 47 5.79893 46.7366 6.26777 46.2678C6.73661 45.7989 7 45.163 7 44.5V32H9V44.5C9 45.163 9.26339 45.7989 9.73223 46.2678C10.2011 46.7366 10.837 47 11.5 47C12.163 47 12.7989 46.7366 13.2678 46.2678C13.7366 45.7989 14 45.163 14 44.5V17H27C27.5304 17 28.0391 16.7893 28.4142 16.4142C28.7893 16.0391 29 15.5304 29 15Z"
      fill="#5A7A84"
    />
    <path
      d="M44.9999 5H14.9199C14.9704 5.331 14.9971 5.66519 14.9999 6C14.9971 6.33481 14.9704 6.669 14.9199 7H43.9999V32H15.9999V34H44.9999C45.2651 34 45.5195 33.8946 45.707 33.7071C45.8946 33.5196 45.9999 33.2652 45.9999 33V6C45.9999 5.73478 45.8946 5.48043 45.707 5.29289C45.5195 5.10536 45.2651 5 44.9999 5Z"
      fill="#A67C52"
    />
  </svg>
);
