import React from "react";

export const Speech = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.009 43C5.683 43 5.355 42.961 5.03 42.88L0.758 41.811C0.312 41.699 0 41.3 0 40.841V2C0 1.447 0.447 1 1 1H3C6.859 1 10 4.141 10 8V13.586L15.209 18.795C15.983 19.569 16.271 20.661 15.98 21.717C15.687 22.772 14.878 23.56 13.817 23.826L10 24.78V29C10 29.407 9.753 29.774 9.375 29.927L6.703 31.009C6.135 31.238 6.081 31.762 6.078 31.916C6.075 32.07 6.109 32.596 6.665 32.847L9.412 34.089C9.771 34.251 10 34.607 10 35V38.998C10 40.238 9.439 41.388 8.462 42.15C7.748 42.708 6.889 43 6.009 43Z"
      fill="#EAD8C5"
    />
    <path
      d="M42.0004 33H16.0004C15.6214 33 15.2754 32.786 15.1054 32.447C14.9364 32.108 14.9724 31.703 15.2004 31.4L21.0004 23.667V12C21.0004 9.243 23.2434 7 26.0004 7H42.0004C44.7574 7 47.0004 9.243 47.0004 12V28C47.0004 30.757 44.7574 33 42.0004 33Z"
      fill="#E6E6E6"
    />
    <path
      d="M40 17H28C27.447 17 27 16.553 27 16C27 15.447 27.447 15 28 15H40C40.553 15 41 15.447 41 16C41 16.553 40.553 17 40 17Z"
      fill="#B3B3B3"
    />
    <path
      d="M34 25H28C27.447 25 27 24.553 27 24C27 23.447 27.447 23 28 23H34C34.553 23 35 23.447 35 24C35 24.553 34.553 25 34 25Z"
      fill="#B3B3B3"
    />
  </svg>
);
