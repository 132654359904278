import React from "react";

export const Diploma = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 1H6C8.761 1 11 3.239 11 6V42C11 44.761 13.239 47 16 47H41V6C41 3.239 38.761 1 36 1Z"
      fill="#E6E6E6"
    />
    <path
      d="M34 12H18C17.448 12 17 11.552 17 11C17 10.448 17.448 10 18 10H34C34.552 10 35 10.448 35 11C35 11.552 34.552 12 34 12Z"
      fill="#B3B3B3"
    />
    <path
      d="M34 20H18C17.448 20 17 19.552 17 19C17 18.448 17.448 18 18 18H34C34.552 18 35 18.448 35 19C35 19.552 34.552 20 34 20Z"
      fill="#B3B3B3"
    />
    <path
      d="M34 28H18C17.448 28 17 27.552 17 27C17 26.448 17.448 26 18 26H34C34.552 26 35 26.448 35 27C35 27.552 34.552 28 34 28Z"
      fill="#B3B3B3"
    />
    <path
      d="M25 36H18C17.448 36 17 35.552 17 35C17 34.448 17.448 34 18 34H25C25.552 34 26 34.448 26 35C26 35.552 25.552 36 25 36Z"
      fill="#B3B3B3"
    />
    <path
      d="M6 1C3.239 1 1 3.239 1 6H11C11 3.239 8.761 1 6 1Z"
      fill="#B3B3B3"
    />
    <path
      d="M21 42C21 44.761 18.761 47 16 47H42C44.761 47 47 44.761 47 42H21Z"
      fill="#B3B3B3"
    />
    <path d="M41 23H33V36L37 34L41 36V23Z" fill="#E86C60" />
    <path
      d="M37 25C32.038 25 28 20.962 28 16C28 11.038 32.038 7 37 7C41.962 7 46 11.038 46 16C46 20.962 41.962 25 37 25Z"
      fill="#EFD358"
    />
    <path
      d="M37 9C33.14 9 30 12.14 30 16C30 19.86 33.14 23 37 23C40.86 23 44 19.86 44 16C44 12.14 40.86 9 37 9ZM37 21C34.243 21 32 18.757 32 16C32 13.243 34.243 11 37 11C39.757 11 42 13.243 42 16C42 18.757 39.757 21 37 21Z"
      fill="#C19A3A"
    />
  </svg>
);
