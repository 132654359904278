import React from "react";

export const KidBall = () => (
  <svg
    width="44"
    height="45"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35 20C37.7614 20 40 17.7614 40 15C40 12.2386 37.7614 10 35 10C32.2386 10 30 12.2386 30 15C30 17.7614 32.2386 20 35 20Z"
      fill="#5A7A84"
    />
    <path
      d="M28.5 9C30.9853 9 33 6.98528 33 4.5C33 2.01472 30.9853 0 28.5 0C26.0147 0 24 2.01472 24 4.5C24 6.98528 26.0147 9 28.5 9Z"
      fill="#EA9860"
    />
    <path
      d="M43.4143 32.586L22.0003 11.172V5C22.0003 4.46957 21.7896 3.96086 21.4145 3.58579C21.0395 3.21071 20.5308 3 20.0003 3C19.4699 3 18.9612 3.21071 18.5861 3.58579C18.211 3.96086 18.0003 4.46957 18.0003 5V12C18.0004 12.5304 18.2112 13.039 18.5863 13.414L23.3183 18.146L0.73233 40.732C0.263427 41.2009 0 41.8369 0 42.5C0 43.1631 0.263427 43.7991 0.73233 44.268C1.20123 44.7369 1.8372 45.0003 2.50033 45.0003C3.16346 45.0003 3.79943 44.7369 4.26833 44.268L15.5003 33.035L18.4653 36L13.7323 40.732C13.2634 41.2009 13 41.8369 13 42.5C13 43.1631 13.2634 43.7991 13.7323 44.268C14.2012 44.7369 14.8372 45.0003 15.5003 45.0003C16.1635 45.0003 16.7994 44.7369 17.2683 44.268L23.2683 38.268C23.6891 37.8456 23.9447 37.2865 23.9887 36.692C24.0327 36.0974 23.8623 35.5067 23.5083 35.027L31.8543 26.682L40.5863 35.414C40.9635 35.7783 41.4687 35.9799 41.9931 35.9753C42.5175 35.9708 43.0191 35.7605 43.39 35.3896C43.7608 35.0188 43.9711 34.5172 43.9757 33.9928C43.9802 33.4684 43.7786 32.9632 43.4143 32.586Z"
      fill="#5A7A84"
    />
  </svg>
);
