import React from "react";

export const PaperApple = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 47H4C3.73478 47 3.48043 46.8946 3.29289 46.7071C3.10536 46.5196 3 46.2652 3 46V2C3 1.73478 3.10536 1.48043 3.29289 1.29289C3.48043 1.10536 3.73478 1 4 1H40C40.2652 1 40.5196 1.10536 40.7071 1.29289C40.8946 1.48043 41 1.73478 41 2V46C41 46.2652 40.8946 46.5196 40.7071 46.7071C40.5196 46.8946 40.2652 47 40 47Z"
      fill="#E6E6E6"
    />
    <path
      d="M32 13H30C29.7348 13 29.4804 12.8946 29.2929 12.7071C29.1054 12.5196 29 12.2652 29 12C29 11.7348 29.1054 11.4804 29.2929 11.2929C29.4804 11.1054 29.7348 11 30 11H32C32.2652 11 32.5196 11.1054 32.7071 11.2929C32.8946 11.4804 33 11.7348 33 12C33 12.2652 32.8946 12.5196 32.7071 12.7071C32.5196 12.8946 32.2652 13 32 13Z"
      fill="#B3B3B3"
    />
    <path
      d="M24 13H12C11.7348 13 11.4804 12.8946 11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11H24C24.2652 11 24.5196 11.1054 24.7071 11.2929C24.8946 11.4804 25 11.7348 25 12C25 12.2652 24.8946 12.5196 24.7071 12.7071C24.5196 12.8946 24.2652 13 24 13Z"
      fill="#B3B3B3"
    />
    <path
      d="M32 21H30C29.7348 21 29.4804 20.8946 29.2929 20.7071C29.1054 20.5196 29 20.2652 29 20C29 19.7348 29.1054 19.4804 29.2929 19.2929C29.4804 19.1054 29.7348 19 30 19H32C32.2652 19 32.5196 19.1054 32.7071 19.2929C32.8946 19.4804 33 19.7348 33 20C33 20.2652 32.8946 20.5196 32.7071 20.7071C32.5196 20.8946 32.2652 21 32 21Z"
      fill="#B3B3B3"
    />
    <path
      d="M24 21H12C11.7348 21 11.4804 20.8946 11.2929 20.7071C11.1054 20.5196 11 20.2652 11 20C11 19.7348 11.1054 19.4804 11.2929 19.2929C11.4804 19.1054 11.7348 19 12 19H24C24.2652 19 24.5196 19.1054 24.7071 19.2929C24.8946 19.4804 25 19.7348 25 20C25 20.2652 24.8946 20.5196 24.7071 20.7071C24.5196 20.8946 24.2652 21 24 21Z"
      fill="#B3B3B3"
    />
    <path
      d="M19 29H12C11.7348 29 11.4804 28.8946 11.2929 28.7071C11.1054 28.5196 11 28.2652 11 28C11 27.7348 11.1054 27.4804 11.2929 27.2929C11.4804 27.1054 11.7348 27 12 27H19C19.2652 27 19.5196 27.1054 19.7071 27.2929C19.8946 27.4804 20 27.7348 20 28C20 28.2652 19.8946 28.5196 19.7071 28.7071C19.5196 28.8946 19.2652 29 19 29Z"
      fill="#B3B3B3"
    />
    <path
      d="M19 37H12C11.7348 37 11.4804 36.8946 11.2929 36.7071C11.1054 36.5196 11 36.2652 11 36C11 35.7348 11.1054 35.4804 11.2929 35.2929C11.4804 35.1054 11.7348 35 12 35H19C19.2652 35 19.5196 35.1054 19.7071 35.2929C19.8946 35.4804 20 35.7348 20 36C20 36.2652 19.8946 36.5196 19.7071 36.7071C19.5196 36.8946 19.2652 37 19 37Z"
      fill="#B3B3B3"
    />
    <path
      d="M36 46.134L38.168 46.81C39.1372 47.1299 40.192 47.0673 41.1165 46.6349C42.041 46.2024 42.7653 45.433 43.141 44.484L45.641 37.484C47.391 32.573 42.541 27.837 37.223 29.259L36 29.582L34.782 29.256C29.461 27.834 24.614 32.57 26.364 37.481L28.864 44.481C29.2397 45.43 29.9639 46.1994 30.8884 46.6319C31.8129 47.0643 32.8678 47.1269 33.837 46.807L36 46.134Z"
      fill="#E86C60"
    />
    <path
      d="M42 21V22C42 23.3261 41.4732 24.5979 40.5355 25.5355C39.5979 26.4732 38.3261 27 37 27V26C37 24.6739 37.5268 23.4021 38.4645 22.4645C39.4021 21.5268 40.6739 21 42 21Z"
      fill="#72C472"
    />
  </svg>
);
