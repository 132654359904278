import React from "react";

export const Painting = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M42 5H6V34H42V5Z" fill="white" />
    <path
      d="M26 6H22C21.448 6 21 5.552 21 5V1C21 0.448 21.448 0 22 0H26C26.552 0 27 0.448 27 1V5C27 5.552 26.552 6 26 6Z"
      fill="#A67C52"
    />
    <path
      d="M36.9557 46.706L32.9557 33.706C32.7927 33.177 32.2297 32.881 31.7057 33.044C31.1777 33.206 30.8817 33.766 31.0437 34.294L32.4917 39H15.5077L16.9557 34.294C17.1187 33.766 16.8217 33.207 16.2937 33.044C15.7687 32.88 15.2057 33.176 15.0437 33.706L11.0437 46.706C10.8807 47.234 11.1777 47.793 11.7057 47.956C11.8037 47.986 11.9027 48 11.9997 48C12.4267 48 12.8227 47.724 12.9547 47.293L14.8977 40.979C14.9337 40.983 14.9637 41 14.9997 41H32.9997C33.0357 41 33.0657 40.983 33.1017 40.979L35.0447 47.293C35.1757 47.724 35.5717 48 35.9997 48C36.0977 48 36.1967 47.985 36.2937 47.955C36.8217 47.793 37.1177 47.234 36.9557 46.706Z"
      fill="#A67C52"
    />
    <path
      d="M20 17C17.795 17 16 15.206 16 13C16 10.794 17.795 9 20 9C22.205 9 24 10.794 24 13C24 15.206 22.205 17 20 17Z"
      fill="#EFD358"
    />
    <path
      d="M31 35H6C5.448 35 5 34.552 5 34V28C5 27.711 5.125 27.438 5.341 27.248L13.341 20.248C13.699 19.934 14.23 19.918 14.607 20.206L31.607 33.206C31.948 33.466 32.084 33.915 31.947 34.321C31.81 34.727 31.429 35 31 35Z"
      fill="#72C472"
    />
    <path
      d="M42.0002 35H15.0002C14.5962 35 14.2312 34.756 14.0762 34.383C13.9212 34.009 14.0072 33.579 14.2932 33.293L30.2932 17.293C30.6582 16.929 31.2362 16.901 31.6332 17.226L42.6332 26.226C42.8652 26.416 43.0002 26.7 43.0002 27V34C43.0002 34.552 42.5522 35 42.0002 35Z"
      fill="#4DA34D"
    />
    <path
      d="M42 4H6C5.448 4 5 4.448 5 5V34C5 34.552 5.448 35 6 35H42C42.552 35 43 34.552 43 34V5C43 4.448 42.552 4 42 4ZM41 33H7V6H41V33Z"
      fill="#C19F85"
    />
  </svg>
);
