import React from "react";

export const Books = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 5H2C1.44772 5 1 5.44772 1 6V33H13V6C13 5.44772 12.5523 5 12 5Z"
      fill="#E86C60"
    />
    <path
      d="M12 43H2C1.44772 43 1 42.5523 1 42V32H13V42C13 42.5523 12.5523 43 12 43Z"
      fill="#444444"
    />
    <path
      d="M8 23H6C5.44772 23 5 22.5523 5 22V11C5 10.4477 5.44772 10 6 10H8C8.55229 10 9 10.4477 9 11V22C9 22.5523 8.55229 23 8 23Z"
      fill="white"
    />
    <path
      d="M35.443 3.67615L25.7531 6.14724C25.218 6.28371 24.8948 6.82818 25.0313 7.36334L31.7032 33.526L43.331 30.5607L36.6591 4.39802C36.5226 3.86287 35.9782 3.53967 35.443 3.67615Z"
      fill="#43A6DD"
    />
    <path
      d="M44.8331 40.4977L35.1432 42.9687C34.6081 43.1052 34.0636 42.782 33.9271 42.2469L31.4561 32.557L43.0839 29.5917L45.555 39.2815C45.6915 39.8167 45.3683 40.3612 44.8331 40.4977Z"
      fill="#444444"
    />
    <path
      d="M36.0148 22.1064L34.0769 22.6006C33.5417 22.7371 32.9973 22.4139 32.8608 21.8787L30.1426 11.2198C30.0061 10.6847 30.3293 10.1402 30.8645 10.0037L32.8024 9.50952C33.3376 9.37305 33.882 9.69624 34.0185 10.2314L36.7367 20.8903C36.8732 21.4254 36.55 21.9699 36.0148 22.1064Z"
      fill="white"
    />
    <path
      d="M24 5H14C13.4477 5 13 5.44772 13 6V33H25V6C25 5.44772 24.5523 5 24 5Z"
      fill="#E6E6E6"
    />
    <path
      d="M24 43H14C13.4477 43 13 42.5523 13 42V32H25V42C25 42.5523 24.5523 43 24 43Z"
      fill="#B3B3B3"
    />
    <path
      d="M20 23H18C17.4477 23 17 22.5523 17 22V11C17 10.4477 17.4477 10 18 10H20C20.5523 10 21 10.4477 21 11V22C21 22.5523 20.5523 23 20 23Z"
      fill="white"
    />
  </svg>
);
