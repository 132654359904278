import React from "react";

export const Book = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45 45H3C1.89543 45 1 44.1046 1 43V9C1 7.89543 1.89543 7 3 7H45C46.1046 7 47 7.89543 47 9V43C47 44.1046 46.1046 45 45 45Z"
      fill="#444444"
    />
    <path
      d="M24 40C24 40 19.1798 38.1035 7.00029 38.0041C6.45017 37.9996 6 37.5556 6 37.0055V6C18.9167 6 24 8 24 8V40Z"
      fill="#B3B3B3"
    />
    <path
      d="M24 40C24 40 28.8202 38.1035 40.9997 38.0041C41.5498 37.9996 42 37.5556 42 37.0055V6C29.0833 6 24 8 24 8V40Z"
      fill="#B3B3B3"
    />
    <path
      d="M24 39C24 39 17.2797 35.205 4.99438 35.008C4.44716 34.9992 4 34.56 4 34.0127V4.01288C4 3.4556 4.46262 2.99921 5.01982 3.00838C17.289 3.21028 24 7.00001 24 7.00001V39Z"
      fill="#E6E6E6"
    />
    <path
      d="M24 39C24 39 30.7202 35.205 43.0056 35.008C43.5528 34.9992 44 34.56 44 34.0127V4.01288C44 3.4556 43.5374 2.99921 42.9802 3.00838C30.711 3.21028 24 7.00001 24 7.00001V39Z"
      fill="#D1D1D1"
    />
  </svg>
);
